(function($, undefined){

    /*main*/
    $('.js-menuBtn').click(function(){
        $(this).toggleClass('active');
        $('.js-mobMenu').slideToggle();
    });
    /*main*/

    /*prod*/
    $('.js-prodThumb').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        asNavFor: '.js-prodSlider',
        focusOnSelect: true,
        vertical: true,
        responsive: [
            {
                breakpoint: 1460,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });
    $('.js-prodSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.js-prodThumb',
        dots: false,
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 1460,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });

    $('.js-prodTab').click(function(e){
        e.preventDefault();
        $('.js-prodTab').removeClass('active');
        $('.js-prodCont').removeClass('active');
        $(this).addClass('active');
        $($(this).attr('href')).addClass('active');
    });

    $('.js-modalBtn').click(function(e){
        e.preventDefault();
        if($(this).hasClass('js-orderBtn')) {
            $('.js-prod').val($(this).attr('data-title'));
        }
        getModal($(this).attr('href'));
    });

    $('.js-modalCls, .js-modalOverlay').click(function(){
        $('.js-modalBox').fadeOut();
    })

    function getModal(id) {
        $('.js-modalBox').fadeOut();
        $('.js-modalBox'+id).fadeIn();
    }
    /*prod*/

    if($('.js-catTxt').length > 0) {
        $('.js-catTxt').each(function(){
            if($(this).outerHeight(false) > 113) {
                $(this).siblings('.js-catMore').show();
                $(this).height(92)
            }
        })
    }
    $('.js-catMore').click(function(e){
        e.preventDefault();
        if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).text('Свернуть');
            $(this).siblings('.js-catTxt').css('height', 'auto');
        } else {
            $(this).removeClass('active');
            $(this).text('Развернуть');
            $(this).siblings('.js-catTxt').css('height', 113);
        }
    });

})(jQuery);
